import React from 'react';
import 'antd/dist/antd.css';
import { Button, Result } from 'antd';
import { Link } from "react-router-dom";


const Success = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const course = queryParams.get("course");
    const amt = queryParams.get("amt");
    const name = queryParams.get("name");
    const number = queryParams.get("number");
    const email = queryParams.get("email");
    console.log({ course, amt, name, number, email });
    return <Result
        status="success"
        title={`Successfully Paid NRS ${amt}!`}
        subTitle="Thank you for the Payment. We shall contact you shortly."
        extra={[
            <a href='/'><Button type="primary" key="console">
                Go Back
            </Button></a>,
        ]}
    />

}
;

export default Success;