import React from "react";

import Payment from "./pages/payment"
import Login from "./pages/login"
import Success from "./pages/success"
import Fail from "./pages/fail";
import Home from "./pages/Home";

export default function App() {
  let component;
  switch (window.location.pathname) {
    case "/":
      component = <Home/>
      break;
    case "/payment":
      component = <Payment/>
      break;
    case "/login":
      component = <Login/>
      break;
    case "/success":
      component = <Success/>
      break;
    case "/fail":
      component = <Fail/>
      break;
    default:
      component = <Payment/>
  }
  return (
    <>
      <div>{component}</div>
    </>
  );
}