import { Row, Col } from "antd/lib/grid"
import React, { useState } from 'react';
import 'antd/dist/antd.css';
import KhaltiCheckout from "khalti-checkout-web";
import {
  Form,
  Input,
  Select,
  Radio
} from 'antd';
import { paymentMethods } from "../const/enum.js"

const Payment = ({ productName, passedAmt = 0 }) => {
  const esewaCharge = 1.025;
  const khaltiCharge = 1.03;
  const [provider, setProvider] = useState(paymentMethods.ESEWA);
  const [chargeLabel, setchargeLabel] = useState("Including Esewa Charges");
  const [amt, setAmt] = useState((passedAmt * esewaCharge).toFixed(2));
  const [originalAmt, setOriginalAmt] = useState(passedAmt);
  const [name, setName] = useState("anon");
  const [number, setNumber] = useState("anon");
  const [email, setEmail] = useState("anon");
  async function postDetail() {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({ name, email, phone: number, course: productName })
    };

    fetch('https://script.google.com/macros/s/AKfycbyPrlc3Ht30_uCPggbJuZPLesg--7jL4eJ72GQPCtWV2IvbM91eqWbazuMrJ9Tqysb-eQ/exec', options)
  }
  const khaltiClick = () => {
    postDetail();
    let config = {
      // replace this key with yours
      "publicKey": "live_public_key_87c64fb36a384808ab6418a235c56deb",
      "productIdentity": "1",
      "productName": `${productName}:${name}:${new Date().toISOString()}:${number}`,
      "productUrl": "http://srtraders.com.np",
      "eventHandler": {
        onSuccess(payload) {
          window.location.replace(khaltiSuccessUri);
        },
        onError(error) {
          window.location.replace(khaltiFailUri);
        },
      },
      "paymentPreference": ["KHALTI", "EBANKING", "MOBILE_BANKING", "CONNECT_IPS", "SCT"],
    };

    let checkout = new KhaltiCheckout(config);

    checkout.show({ amount: amt * 100 });
  };
  const esewaSuccessUri = `${process.env.REACT_APP_BASE_URL}/success?course=${productName}&amt=${amt}&name=${name}number=${number}&email=${email}`;
  const esewaFailUri = `${process.env.REACT_APP_BASE_URL}/fail?course=${productName}&amt=${amt}&name=${name}number=${number}&email=${email}`;
  const khaltiSuccessUri = `${process.env.REACT_APP_BASE_URL}/success?course=${productName}&amt=${amt}&name=${name}number=${number}&email=${email}`;
  const khaltiFailUri = `${process.env.REACT_APP_BASE_URL}/fail?course=${productName}&amt=${amt}&name=${name}number=${number}&email=${email}`;


  return (
    <>
      <Form
        method="POST"
        name='esewa'
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item label="FullName" name="name" >
          <Input onChange={(v) => {
            setName(v.target.value)
          }} />
        </Form.Item>
        <Form.Item label="Phone Number" name="number">
          <Input onChange={(v) => {
            setNumber(v.target.value)
          }} />
        </Form.Item>
        <Form.Item label="email" name="email">
          <Input onChange={(v) => {
            setEmail(v.target.value)
          }} />
        </Form.Item>
        <Form.Item label="Payment Method">
          <Select defaultValue={paymentMethods.ESEWA} onChange={(v) => {
            setProvider(v)
            setchargeLabel(`Including ${v} Charges`)
            if (v === paymentMethods.ESEWA) {
              let calculated = originalAmt * esewaCharge
              setAmt(calculated.toFixed(2))
            }
            else if (v === paymentMethods.KHALTI) {
              let calculated = originalAmt * khaltiCharge
              setAmt(calculated.toFixed(2))
            }
          }}>
            <Select.Option selected={true} value={paymentMethods.ESEWA}>Esewa</Select.Option>
            <Select.Option value={paymentMethods.KHALTI}>Khalti</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item hidden={passedAmt ? true : false} label="Amount" name="amt"
          rules={[
            {
              required: true,
              message: 'Amount Must be greater than 10',
            },
          ]}>
          <Input hidden={passedAmt ? true : false} value={amt} onChange={(v) => {
            // setAmt(v.target.value * 0.025 + v.target.value * 1);
            setOriginalAmt(v.target.value);
            setAmt((v.target.value * (provider === paymentMethods.ESEWA ? esewaCharge : khaltiCharge)).toFixed(2));
          }} />
        </Form.Item>
        <Form.Item label={chargeLabel}>
          <Input value={amt} disabled={true}></Input>
        </Form.Item>
      </Form>
      {provider === paymentMethods.ESEWA ?
        <form action='http://esewa.com.np/epay/main' method='POST'>
          <input value={amt} name='tAmt' type='hidden' />
          <input value={amt} name='amt' type='hidden' />
          <input value='0' name='txAmt' type='hidden' />
          <input value='0' name='psc' type='hidden' />
          <input value='0' name='pdc' type='hidden' />
          <input value="NP-ES-SRTRADERS" name='scd' type='hidden' />
          <input value={`${productName}:${name}:${new Date().toISOString()}:${number}`} name='pid' type='hidden' />
          <input
            value={esewaSuccessUri}
            type='hidden'
            name='su'
          />
          <input
            value={esewaFailUri}
            type='hidden'
            name='fu'
          />
          <input
            id="postEsewa"
            className=' h-12 ml-5 w-24 bg-primary rounded-lg hover:bg-secondary text-white text-md font-semibold cursor-pointer text-white'
            value='Continue'
            type='submit'
            hidden={true}
          />
          <Row>
            <Col xs={2} sm={4} md={6} lg={8} xl={10}>
            </Col>
            <Col xs={20} sm={16} md={12} lg={8} xl={4}>
              <Form.Item label="Pay Now   ">
                {
                  amt > 10 ? <input
                    id="postEsewa"
                    value='Continue With eSewa'
                    type='submit'
                    onClick={postDetail}
                  /> : <input
                    id="postEsewa"
                    value='Amount Must be Greater than 10'
                    type='submit'
                    disabled={true}
                  />
                }
              </Form.Item>
            </Col>
            <Col xs={2} sm={4} md={6} lg={8} xl={10}>
            </Col>
          </Row>
        </form>
        : <Row>
          <Col xs={2} sm={4} md={6} lg={8} xl={10}>
          </Col>
          <Col xs={20} sm={16} md={12} lg={8} xl={4}>
            <Form.Item label="Pay Now   ">
              {
                amt > 10 ? <input
                  id="postKhalti"
                  value='Continue With Khalti'
                  type='submit'
                  onClick={khaltiClick}
                /> : <input
                  id="postKhalti"
                  value='Amount Must be Greater than 10'
                  type='submit'
                  disabled={true}
                />
              }
            </Form.Item>
          </Col>
          <Col xs={2} sm={4} md={6} lg={8} xl={10}>
          </Col>
        </Row>
      }
    </>
  );
};


export default (props) => {
  return <Payment passedAmt={props.passedAmt} productName={props.productName} />
};


