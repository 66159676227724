import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { CheckCircleOutlined, } from '@ant-design/icons';
import { Avatar, Button, Card, Modal } from 'antd';
import Payment from "../pages/payment"
const { Meta } = Card;


const CourseCard = ({ imageUrl, title, days, amt }) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const showModal = () => {
        setVisible(true);
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setVisible(false);
    };
    return <>
        <Card
            style={{
                width: 400,
            }}
            cover={
                <img
                    alt="example"
                    src={imageUrl}
                />
            }
            actions={[
                <Button onClick={showModal}>Enroll Now</Button>,
            ]}
        >
            <Meta
                avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                title={title}
                description={`NRS ${amt} , ${days} Days`}
            />
        </Card>
        <Modal
            title="Title"
            visible={visible}
            // onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={null}
        >
            {<Payment passedAmt={amt} productName="AWS Certified Devops Engineer" />}
        </Modal>
    </>
}

export default CourseCard;