import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { CheckCircleOutlined, } from '@ant-design/icons';
import { Avatar, Button, Card, Modal } from 'antd';
import Payment from "./payment";
import CourseCard from "../components/courseCard"

const { Meta } = Card;

const data = [
    {
        title: "AWS Certified Devops Engineer",
        cost: 55000,
        days: 15,
        imageUrl: 'https://images.unsplash.com/photo-1628296499994-70face79ab36?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1373&q=80'
    },
    {
        title: "AWS Certified SysOps Engineer",
        cost: 45000,
        days: 20,
        imageUrl: 'https://images.unsplash.com/photo-1561736778-92e52a7769ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Y2xvdWQlMjBjb21wdXRpbmd8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60'
    },
    {
        title: "Demo",
        cost: 10,
        days: 20,
        imageUrl: 'https://images.unsplash.com/photo-1561736778-92e52a7769ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Y2xvdWQlMjBjb21wdXRpbmd8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60'
    },
];

const Home = () => {

    // const handleOk = () => {
    //     setModalText('The modal will be closed after two seconds');
    //     setConfirmLoading(true);
    //     setTimeout(() => {
    //         setVisible(false);
    //         setConfirmLoading(false);
    //     }, 2000);
    // };

    return data.map((d) => {
        return <CourseCard amt={d.cost} imageUrl={d.imageUrl} days={d.days} title={d.title} key={`${d.days}${d.title}`}  />
    })
};

export default Home;

// http://3.217.104.27/