import React from 'react';
import 'antd/dist/antd.css';
import { Button, Result } from 'antd';
import { Link } from "react-router-dom";


const Fail = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const course = queryParams.get("course");
    const amt = queryParams.get("amt");
    const name = queryParams.get("name");
    const number = queryParams.get("number");
    const email = queryParams.get("email");
    console.log({ course, amt, name, number, email });
    return <Result
        status="warning"
        title={`There was some problem processing your payment of NRS ${amt}`}
        subTitle="Please try again"
        extra={
            <a href='/'><Button type="primary" key="console">
                Go Back
            </Button></a>
        }
    />

}
    ;

export default Fail;